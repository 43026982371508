.gallery-box {
    background-color: #ffff;
    padding: 40px;

    .gallery-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 26px;

        .gallery-grid-items {

            .card-image {
                width: 100%;
                height: 230px;
                border-radius: 7px 7px 0 0;
                background-color: #f5f5f5;

                img {
                    width: 100%;
                    height: 230px;
                    object-fit: cover;
                }
            }

            .card-details {
                border-radius: 0 0 7px 7px;
                padding: 12px;
                background: #fff;
                border-top: none !important;
                border: 1px solid #BABABA;
                display: flex;
                justify-content: space-between;
                box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;

                h1 {
                    font-size: 18px;
                    line-height: 21px;
                    color: #343434;
                    margin: 0 0 7px 0;
                }

                p {
                    font-size: 14px;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    line-height: 20px;
                    color: #606060;
                }
            }
        }
    }

    .box-title-alignment {
        display: flex;
        align-items: center;
        padding: 0 0 30px 0;
        justify-content: space-between;

        h1 {
            font-size: 22px;
            line-height: 25px;
            color: #343434;
            margin: 0;
        }

        .button-alignment {
            display: flex;
            align-items: center;

            button:last-child {
                margin-right: 0px;
            }

            button {
                padding: 7px 40px;
                font-size: 18px;
                margin-right: 24px;
                line-height: 21px;
                color: #00AEEF;
                border: 1px solid #00AEEF;
                border-radius: 6px;
                background-color: transparent;
            }
        }
    }
}

.cus-modal {
    background: rgba(0, 0, 0, 0.51);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999;
    display: flex;
    justify-content: center;

    .folder-modal {
        margin: 150px 0 0 0;
        width: 608px;
        height: 450px;
        background-color: #fff;

        .cus-modal-header {
            background: #00AEEF;
            padding: 13px;

            p {
                font-size: 20px;
                line-height: 30px;
                margin: 0;
                color: #fff;
                text-align: center;
            }
        }

        .cus-modal-boy {
            padding: 30px 48px;

            .input-grid {
                display: grid;
                grid-template-columns: 100px minmax(0, 1fr);
                gap: 0 10px;
                padding: 0 0 16px 0;

                .input-grid-items {
                    span {
                        font-size: 14px;
                        line-height: 18px;
                        color: #606060;
                        font-weight: 500;
                    }

                    input {
                        width: 100%;
                        border: 1px solid #BABABA;
                        height: 31px;
                        font-size: 14px;
                        line-height: 18px;
                        color: #606060;
                        font-weight: 500;
                        padding: 0 16px;
                    }

                    textarea {
                        width: 100%;
                        border: 1px solid #BABABA;
                        height: 72px;
                        font-size: 14px;
                        line-height: 18px;
                        color: #606060;
                        font-weight: 500;
                        padding: 0 16px;
                    }

                    .image-preview {
                        width: 100px;
                        height: 100px;
                        border: 1px solid #BABABA;
                        background-color: #f5f5f5;

                        img {
                            width: 100px;
                            height: 100px;
                            object-fit: cover;
                        }
                    }

                    .modal-button-alignment {
                        button:last-child {
                            background-color: #00AEEF;
                            color: #fff;
                            margin-top: 5px;
                            margin-left: 15px;
                        }

                        button {
                            padding: 5px 22px;
                            border: 1px solid #00AEEF;
                            color: #00AEEF;
                            cursor: pointer;
                            cursor: pointer;
                            border-radius: 999px;
                            background-color: transparent;
                            min-width: 140px;
                        }
                    }
                }
            }
        }
    }
}

.pagination-alignment {
    display: flex;
    justify-content: center;
    padding: 0 0 30px 0;

    .pagination-box {
        display: flex;
        justify-content: center;

        .pagination-item {
            padding: 0 10px;
            font-size: 18px;
            line-height: 21px;
            color: #00AEEF;
            border: 1px solid #00AEEF;
            border-radius: 6px;
            background-color: transparent;
        }

        .pagination-box-left {
            margin-right: 10px;

            button {
                padding: 5px 22px;
                border: 1px solid #00AEEF;
                color: #00AEEF;
                cursor: pointer;
                cursor: pointer;
                border-radius: 999px;
                background-color: transparent;
                min-width: 140px;
            }
        }

        .pagination-box-right {
            margin-left: 10px;

            button {
                padding: 5px 22px;
                border: 1px solid #00AEEF;
                color: #00AEEF;
                cursor: pointer;
                cursor: pointer;
                border-radius: 999px;
                background-color: transparent;
                min-width: 140px;
            }
        }

        .page-count {
            font-size: 18px;
            line-height: 21px;
            color: #00AEEF;
            background-color: transparent;
        }
    }
}

.dropdown {
    position: relative;
}

.dropdown-main {
    width: 82px;
    position: absolute;
    background-color: #fff;
    filter: drop-shadow(0 0 15px rgba(0, 0, 0, 14%));
    padding: 13px 0px 13px 12px;
    top: 30px;
    border-radius: 8px;
    right: -30px;

    li {
        list-style: none;

        span {
            display: block;
        }

        .active {
            color: #00AEEF;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.41px;

            &:nth-child(1) {
                padding-bottom: 11px;
            }
        }
    }
}