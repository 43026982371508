.lsq-details-section_Toast {
  // margin: 300px 0 0 0;

  .lsq-details-form-alignment {
    h2 {
      font-size: 24px;
      line-height: 29px;
      font-weight: 500;
      color: #000;
    }

    .lsq-form-alignment {
      margin: 40px 0;
      background: #fff;
      padding: 24px 164px;
      border-radius: 8px;
      box-shadow: 0 0 12px 0 rgb(0 0 0 / 10%);
      display: flex;
      justify-content: center;
      .lsq-input-alignment {
        padding: 15px 0;

        p {
          display: flex;
          justify-content: center;
        }

        &.input-alignment-flex {
          display: flex;
          align-items: center;
          gap: 20px;
        }

        .fa-paper-plane-style {
          font-size: 30px;
          display: flex;
          justify-content: center;
          padding: 15px;

          i {
            border: 1px solid;
            border-radius: 53px;
            padding: 29px;
            color: #00aeef;
            background: #eeeff31f;
          }
        }
        .fa-paper-plane-style_error {
          font-size: 30px;
          display: flex;
          justify-content: center;
          padding: 15px;

          i {
            border: 1px solid;
            border-radius: 53px;
            padding: 29px;
            color: #ed1c24;
            background: #eeeff31f;
          }
        }

        .laq-main-cantainer {
          //  display: flex;
          // justify-content: center
          h2 {
            display: flex;
            justify-content: center;
            font-family: sans-serif;
          }
          p {
            display: flex;
            justify-content: center;
            margin-top: 15px;
          }
          .link-data {
            color: #00aeef;
            display: flex;
            justify-content: center;
            p {
              border-bottom: 1px solid;
              cursor: pointer;
            }
          }
          .link-data-error {
            color: #ed1c24;
            display: flex;
            justify-content: center;
            p {
              border-bottom: 1px solid;
              cursor: pointer;
            }
          }
          .align-itme-button {
            display: flex;
            justify-content: center;
            margin-top: 40px;
            button {
              height: 34px;
              font-size: 14px;
              line-height: 20px;
              color: #fff;
              border: 1px solid rgb(0, 42, 90);
              background: rgb(0, 42, 90);
              cursor: pointer;
              border-radius: 41px;
              padding: 0 26px;
            }
          }
          .align-itme-button-error {
            display: flex;
            justify-content: center;
            margin-top: 40px;
            button {
              height: 34px;
              font-size: 14px;
              line-height: 20px;
              color: #fff;
              border: 1px solid #ed1c24;
              background: #ed1c24;
              cursor: pointer;
              border-radius: 41px;
              padding: 0 26px;
            }
          }
        }

        label {
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          color: #343434;
        }

        .lsq-notes-alignment {
          margin: 4px 0 0 0;

          span {
            font-size: 14px;
            line-height: 17px;
            font-weight: 400;
            color: #606060;
          }
        }

        .lsq-form-input-alignment {
          margin: 8px 0 0 0;

          input {
            width: 100%;
            height: 50px;
            border-radius: 8px;
            padding: 0 14px;
            display: flex;
            align-items: center;
            color: #343434;
            font-size: 18px;
            line-height: 22px;
            font-weight: 400;
            border: 1px solid #c4c4c4;
            background: #fff;
          }

          textarea {
            width: 100%;
            height: 150px;
            border-radius: 8px;
            padding: 14px;
            display: flex;
            align-items: center;
            color: #343434;
            font-size: 18px;
            line-height: 22px;
            font-weight: 400;
            border: 1px solid #c4c4c4;
            background: #fff;

            &:focus-visible {
              outline: none;
            }
          }

          .relative-input-alignmnet {
            position: relative;

            label {
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              color: #343434;
            }

            input {
              width: 100%;
              height: 50px;
              border-radius: 8px;
              padding: 0 14px;
              display: flex;
              align-items: center;
              color: #343434;
              font-size: 18px;
              line-height: 22px;
              font-weight: 400;
              border: 1px solid #c4c4c4;
              background: #fff;
              margin: 4px 0 0 0;
            }

            .drop-down-arrow-icon {
              position: absolute;
              top: 50%;
              right: 15px;
              transform: translateY(-50%);
              cursor: pointer;

              img {
                display: block;
              }
            }

            .dropdown-box-alignment {
              width: 100%;
              height: 50px;
              border-radius: 8px;
              padding: 0 14px;
              display: flex;
              align-items: center;
              color: #343434;
              font-size: 18px;
              line-height: 22px;
              font-weight: 400;
              border: 1px solid #c4c4c4;
              background: #fff;

              &::placeholder {
                color: #bababa;
              }

              .dropdown-box-details-alignment {
                padding: 0 0 10px 0;

                &:last-child {
                  padding: 0;
                }

                span {
                  color: #343434;
                  font-size: 18px;
                  line-height: 22px;
                  font-weight: 400;
                }
              }
            }

            select {
              width: 100%;
              height: 50px;
              border-radius: 8px;
              padding: 0 28px;
              display: flex;
              align-items: center;
              color: #343434;
              font-size: 18px;
              line-height: 22px;
              font-weight: 400;
              border: 1px solid #c4c4c4;
              background: #fff;

              &::placeholder {
                color: #bababa;
              }
            }
          }
        }

        .pic-input-alignment {
          width: 82px;
          min-width: 82px;
          max-width: 82px;
          height: 82px;
          border: 1px dashed #c8c8c8;
          border-radius: 8px;
          background: #fff;
          cursor: pointer;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          input[type="file"] {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
          }

          span {
            color: #343434;

            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
          }

          i {
            font-size: 28px;
          }
        }

        .flex-alignment {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .input-button-alignment {
            display: flex;
            align-items: center;
            gap: 20px;

            .child-input-alignment {
              width: 60px;
              height: 21px;
              border: 1px dashed #c8c8c8;
              border-radius: 8px;
              background: #fbfbfb;
              cursor: pointer;
              position: relative;

              input[type="file"] {
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
              }
            }

            .button-alignment {
              button {
                font-size: 14px;
                line-height: 20px;
                color: #222;
                height: 40px;
                background: #fff;
                border: 1px solid #222;
                border-radius: 30px;
                padding: 0 20px;
              }
            }
          }
        }
      }
    }

    .send-request-button-alignment {
      text-align: center;
      margin: 0 0 30px 0;

      &.fixed[data-show="false"] {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background: #fff;
        -webkit-box-shadow: 0 -3px 4px 0 rgb(0 0 0 / 6%);
        box-shadow: 0 -3px 4px 0 rgb(0 0 0 / 6%);
        z-index: 2;
        height: 86px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
      }

      button {
        height: 48px;
        font-size: 14px;
        line-height: 20px;
        color: #fff;
        border: 1px solid #f60;
        background: #f60;
        cursor: pointer;
        border-radius: 100px;
        padding: 0 24px;
      }
    }
  }
}

.messageCenter-section {
  background: #fff;
  padding: 24px;

  .callRequestsHeadingAlignment {
    padding: 0 0 24px 0;
    display: flex;

    h1 {
      font-size: 22px;
      line-height: 26px;
      font-weight: 500;
      color: #343434;
    }

    .details-alignment {
      display: flex;
      align-items: center;
    }
  }

  .callRequestsDetailsAlignment {
    background: #ffffff;
    border: 1px solid #bababa;
    height: calc(100vh - 280px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(186, 186, 186, 0.3);
      border-radius: 41px;
    }

    &::-webkit-scrollbar-thumb {
      background: #bababa;
      border-radius: 41px;
      height: 41px;
    }

    .callRequestsDetailsboxAlignment {
      border-bottom: 1px solid #bababa;
      padding: 16px;

      &:last-child {
        border-bottom: none;
      }

      .callRequestsNameAlignment {
        h4 {
          font-size: 20px;
          line-height: 24px;
          font-weight: 500;
          color: #343434;
        }
      }

      .callRequestsChildDetailsAlignment {
        display: flex;
        align-items: center;
        gap: 176px;

        @media only screen and (max-width: "1099px") {
          flex-wrap: wrap;
          gap: 30px;
        }

        .secondPartAlignment {
          display: flex;
          align-items: center;
          gap: 100px;

          @media only screen and (max-width: "1099px") {
            justify-content: space-between;
            width: 100%;
            gap: 30px;
            flex-wrap: wrap;
          }

          .callDateDetailsAlignment {
            display: flex;
            align-items: center;
            gap: 4px;
            flex-wrap: wrap;
            padding: 0 0 10px 0;

            h6 {
              font-size: 14px;
              line-height: 17px;
              font-weight: 400;
              color: #bababa;
              margin: 0;
              padding: 0;
            }

            .childDetailsAlignment {
              display: flex;
              align-items: center;
              gap: 3px;

              img {
                width: 19px;
                display: block;
              }

              span {
                font-size: 14px;
                line-height: 17px;
                font-weight: 400;
                color: #606060;
              }
            }
          }

          .contactDetailsAlignment {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 11px;

            .acceptButtonAlignment {
              button {
                background: #00aeef;
                border-radius: 37px;
                width: 104px;
                max-width: 104px;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                color: #fff;
                cursor: pointer;
                border: none;
                height: 37px;
              }
            }

            .declineButtonAlignment {
              button {
                background: #ffffff;
                border: 1px solid #ed1c24;
                border-radius: 37px;
                width: 104px;
                max-width: 104px;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                color: #ed1c24;
                cursor: pointer;
                height: 37px;
              }
            }

            .messageAlignment {
              button {
                background: #fff;
                border: none;
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 14px;
                line-height: 17px;
                font-weight: 400;
                text-decoration-line: underline;
                color: #00aeef;
                cursor: pointer;

                img {
                  width: 17px;
                  display: block;
                }
              }
            }
          }
        }

        .profileNameAlignment {
          display: flex;
          align-items: center;
          gap: 8px;
          margin: 14px 0 0 0;

          .logoAlignment {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background: rgba(186, 186, 186, 0.1);
            border: 1px solid #bababa;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
              font-size: 24px;
              line-height: 30px;
              font-weight: 500;
              color: #606060;
            }
          }

          .nameAlignment {
            span {
              font-size: 14px;
              line-height: 20px;
              font-weight: 400;
              color: #606060;
            }
          }
        }

        .callDetailsAlignment {
          padding: 13px 0 0 0;
          display: flex;
          align-items: center;
          gap: 10px;

          .callButtonAlignment {
            button {
              padding: 4px 8px;
              background: #fff;
              border: 1px solid #f5821f;
              border-radius: 5px;
              font-size: 14px;
              line-height: 17px;
              font-weight: 400;
              color: #f5821f;
              cursor: pointer;
            }
          }

          .callTimeAlignment {
            p {
              font-size: 14px;
              line-height: 20px;
              font-weight: 400;
              color: #606060;
            }
          }
        }

        .tagAlignment {
          display: flex;
          align-items: center;
          gap: 10px;
          flex-wrap: wrap;
          margin: 13px 0 0 0;

          .tagNameAlignment {
            border: 1px solid #bababa;
            border-radius: 5px;
            padding: 4px 8px;

            p {
              font-size: 14px;
              line-height: 17px;
              font-weight: 400;
              color: #606060;
            }
          }
        }
      }
    }
  }
}

.messageCenter-section {
  background: #fff;
  padding: 24px;

  .callRequestsHeadingAlignment {
    padding: 0 0 24px 0;
    gap: 10px;

    h1 {
      line-height: 26px;
      line-height: 26px;
      font-weight: 500;
      color: #343434;
    }
  }

  .callRequestsDetailsAlignment {
    background: #ffffff;
    border: 1px solid #bababa;
    height: calc(100vh - 280px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(186, 186, 186, 0.3);
      border-radius: 41px;
    }

    &::-webkit-scrollbar-thumb {
      background: #bababa;
      border-radius: 41px;
      height: 41px;
    }
  }

  .quotesManagementMainSection {
    // padding: 24px 0;
    .quotesManagementMainTable {
      width: 100%;
      max-width: 100%;
      overflow-x: auto;

      table {
        width: 100%;
        border: 1px solid #bababa;

        thead {
          tr {
            background: rgba(186, 186, 186, 0.1);

            th {
              // @include text-sm-normal;
              color: #343434;
              padding: 8px 0 8px 14px;
              border-bottom: 1px solid #bababa;

              input[type="checkbox"] {
                width: 12px;
                height: 12px;
                cursor: pointer;
                margin: 0;
                padding: 0;
              }

              img {
                width: 8px;
                margin-left: 5px;
                cursor: pointer;
              }
            }
          }
        }

        tbody {
          tr {
            &.firstRowAlignment {
              td {
                padding: 0;
              }
            }

            &.boderAlignment {
              &:last-child {
                td {
                  border-bottom: none;
                }
              }

              td {
                border-bottom: 1px solid #bababa;
              }
            }

            td {
              padding: 2px 0 14px 14px;

              .pricingAlignment {
                p {
                  font-size: 14px;
                  line-height: 17px;
                  font-weight: 400;
                  font-weight: 400;
                  color: #606060;
                  padding: 0 0 5px 0;

                  &:last-child {
                    padding: 0;
                  }
                }
              }

              .dateDetailsAlignment {
                padding: 14px;
                display: flex;
                align-items: center;
                gap: 12px;

                input[type="checkbox"] {
                  width: 12px;
                  height: 12px;
                  margin: 0;
                  padding: 0;
                  cursor: pointer;
                }

                span {
                  font-size: 14px;
                  line-height: 17px;
                  font-weight: 400;
                  color: #606060;
                }
              }

              .productDetailsAlignment {
                display: flex;
                align-items: center;
                gap: 8px;

                img {
                  width: 31px;
                  height: 31px;
                  object-fit: cover;
                  border: 1px solid #e0e0e0;
                }

                p {
                  font-size: 14px;
                  line-height: 17px;
                  font-weight: 400;
                  font-weight: 400;
                  color: #606060;
                }
              }

              .trademarkAlignment {
                display: flex;
                align-items: center;
                gap: 8px;

                img {
                  width: 28px;
                  height: 28px;
                  object-fit: cover;
                  border-radius: 50%;
                }

                .companyNameAlignment {
                  h4 {
                    font-size: 14px;
                    line-height: 17px;
                    font-weight: 400;
                    font-weight: 400;
                    color: #343434;
                  }

                  .differentAlignment {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    p {
                      width: 7px;
                      height: 7px;
                      background: #c4c4c4;
                      border-radius: 40px;
                    }

                    .messageImg {
                      img {
                        width: 14px;
                        height: 10px;
                        border-radius: 0;
                      }
                    }

                    .userImgAlignment {
                      img {
                        width: 9px;
                        height: 9px;
                        border-radius: 0;
                      }
                    }
                  }
                }
              }

              .flagAlignment {
                img {
                  width: 24px;
                  border-radius: 1px;
                }
              }

              .viewAllAlignment {
                p {
                  font-size: 14px;
                  line-height: 17px;
                  font-weight: 400;
                  font-weight: 400;
                  color: #00aeef;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }

    .quotesManagementMainTable table thead tr th:nth-child(1) {
      width: 2%;
    }

    .quotesManagementMainTable table thead tr th:nth-child(2) {
      width: 10%;
    }

    .quotesManagementMainTable table thead tr th:nth-child(3) {
      width: 15%;
    }

    .quotesManagementMainTable table thead tr th:nth-child(4) {
      width: 15%;
    }

    .quotesManagementMainTable table thead tr th:nth-child(5) {
      width: 10%;
    }

    .quotesManagementMainTable table thead tr th:nth-child(6) {
      width: 10%;
    }

    .quotesManagementMainTable table thead tr th:nth-child(7) {
      width: 15%;
    }

    .quotesManagementMainTable table thead tr th:nth-child(8) {
      width: 13%;
    }

    .quotesManagementMainTable table thead tr th:nth-child(9) {
      width: 10%;
    }
  }
}

.lsq-details {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .lsq-uploadedImg-section {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 82px;
    height: 82px;
    .uplodedImg-alignment {
      img {
        height: 100%;
        width: 100%;
        border-radius: 8px;
      }
    }

    .closeImg-alignment {
      position: absolute;
      top: -5px;
      right: -5px;
    }
  }
}

.quote-alignment {
  display: flex;
  justify-content: center;
}

.chngesImagesModal {
  .modalwrapper {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(3px);
    animation: 300ms ease 0s 1 normal none running OpenFade;
    z-index: 99999999999;
    display: flex;
    justify-content: center;
    align-items: center;

    .chngesImagesModalBox {
      background: #ffffff;
      padding: 16px;
      border-radius: 10px;
      // width: 400px;
      // max-width: 400px;
      // height: 300px;
      position: relative;

    //   @include breakpoint("max-sm") {
    //     width: 340px;
    //     max-width: 340px;
    //   }

      .header-aligment {
        padding: 0 0 20px 0;

        h4 {
          font-size: 18px;
          color: #343434;
          text-align: center;
        }
      }

      .inputAlignment {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0 30px;

        select {
          background: #f2f2f2;
          border-radius: 4px;
          width: 100%;
          height: 32px;
          color: #929292;
          //    font-size: 14px;
          line-height: 17px;
          font-weight: 400;
          border: none;
          padding: 0 10px;
        }

        .lsq-form-input-alignment {
          // display: flex;
          // align-items: center;
          // gap: 20px;

          label {
            font-family: "Lato";
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #343434;
          }

          p {
            font-size: 16px;
            font-weight: 400;
            text-transform: capitalize;
            color: #606060;
            max-width: 420px;
            text-align: start;
          }
        }
      }

      .buttonAlignment {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;
        gap: 10px;

        button {
          padding: 0 15px;
          background: #00aeef;
          // @include text-sm-meduim;
          // display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          height: 100%;
          color: #ffffff;
          gap: 8px;
          //cursor: pointer;
          height: 30px;
          border-radius: 8px;

          &:first-child {
            border: 1px solid #00aeef;
            color: #00aeef;
            background: #ffffff;
          }
        }
      }

      .closeButtonAlignment {
        position: absolute;
        top: 8px;
        right: 8px;

        i {
          color: #00aeef;
        }

        img {
          width: 20px;
          height: 20px;
          display: block;
          //cursor: pointer;
        }
      }

      .headingAlignment {
        h2 {
          font-family: "Roboto", sans-serif;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          color: #343434;
        }
      }

      .modalBodyAlignment {
        margin: 20px 0 0 0;
        height: 200px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        overflow-y: auto;

        .uploadImgBox {
          width: 87px;
          height: 65px;
          background: rgba(255, 255, 255, 0.8);
          border: 1px dashed #bababa;
          // display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          padding: 0 20px;

          p {
            font-family: "Roboto";
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            text-transform: capitalize;
            color: #bababa;
          }

          input {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            right: 0;
            opacity: 0;
          }
        }

        .changesButtonAlignment {
          margin: 20px 0 0 0;

          button {
            background: #00aeef;
            color: #ffffff;
            border: none;
            font-family: "Roboto";
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            border-radius: 30px;
            padding: 8px 16px;
            //cursor: pointer;
          }
        }

        .DeleteButtonAlignment {
          margin: 20px 0 0 0;

          button {
            background: #00aeef;
            color: #ffffff;
            border: none;
            font-family: "Roboto";
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            border-radius: 30px;
            padding: 8px 16px;
            //cursor: pointer;
          }
        }

        .DeleteButtonAlignment {
          margin: 20px 0 0 0;

          button {
            background: #00aeef;
            color: #ffffff;
            border: none;
            font-family: "Roboto";
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            border-radius: 30px;
            padding: 8px 16px;
            //cursor: pointer;
          }
        }

        select {
          height: 100%;
          width: 100%;
          background: rgba(186, 186, 186, 0.1);
          border: 1px solid #bababa;
          padding: 7px 10px;

          &::placeholder {
            color: #bababa;
          }
        }
      }

      .lsq-form-input-alignment {
        display: flex;
        align-items: center;
        gap: 20px;

        label {
          font-family: "Lato";
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #343434;
        }

        p {
          line-height: 19px;
          font-weight: 400;
          text-transform: capitalize;
          color: #606060;
          max-width: 420px;
          text-align: start;
        }
      }
    }
  }
}

.quantityRequiredModalAlignment {
  .modalwrapper {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(3px);
    animation: 300ms ease 0s 1 normal none running OpenFade;
    z-index: 99999999999;
    display: flex;
    justify-content: center;
    align-items: center;

    .quantityRequiredBox {
      background: #ffffff;
      padding: 16px;
      border-radius: 10px;
      width: 950px;
      max-width: 950px;
      // height: 300px;
      position: relative;

    //   @include breakpoint("max-sm") {
    //     width: 340px;
    //     max-width: 340px;
    //   }

      .closeButtonAlignment {
        position: absolute;
        top: -8px;
        right: -8px;

        img {
          width: 20px;
          height: 20px;
          display: block;
        }
      }

      .quantityRequireAlignment {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 99px;
        background: #ffffff;
        border-bottom: 1px solid #bababa;

        &:last-child {
          border-bottom: none;
        }

        .quantityRequireDetails {
          padding: 12px;

          .quantityRequireproductDetails {
            display: flex;
            gap: 19px;

            .quantityRequireproductImgAlignment {
              img {
                width: 99px;
                height: 83px;
                border-radius: 4px;
                object-fit: cover;
                cursor: pointer;
              }
            }

            .quantityRequireproductDetailsAlignment {
              display: flex;
              flex-direction: column;
              gap: 7px;

              span {
                font-family: "Lato";
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #f5821f;

                .quantityRequireproductNameAlignment {
                  color: #343434;
                  margin: 0 0 0 4px;
                }
              }

              .quantityRequireproductDespriction {
                max-width: 247px;

                span {
                  font-family: "Lato";
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 14px;
                  color: #606060;
                }
              }
            }
          }
        }

        .quantityRequirepostedQuoteDetails {
          padding: 16px 12px;
          display: grid;
          grid-template-columns: 235px auto;
          gap: 50px;

          .quantityRequirepostedNameDetails {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .quantityRequirepostedLogoAlignment {
              display: flex;
              align-items: center;
              gap: 5px;

              .quantityRequirelogoAlignment {
                background: rgba(186, 186, 186, 0.1);
                border: 1px solid #bababa;
                border-radius: 50%;
                width: 35px;
                height: 35px;
                display: flex;
                text-align: center;
                justify-content: center;

                span {
                  font-family: "Roboto";
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 21px;
                  color: #606060;
                  padding: 7px 12px;
                }
              }

              .quantityRequirenameAlignment {
                span {
                  font-family: "Lato";
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 19px;
                  color: #606060;
                }
              }
            }

            .quantityRequireotherDetailsHeadingAlignment {
              span {
                font-family: "Lato";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #606060;

                &.detailsSection {
                  color: #bababa;
                }
              }

              img {
                width: 19px;
                height: 17px;
              }
            }
          }

          .quantityRequirequotesDetailsAlignment {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 9px;

            .quantityRequiContactButton {
              button {
                background: #ffffff;
                border-radius: 37px;
                padding: 9px 12px;
                border: 1px solid #00aeef;
                font-family: "Roboto";
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #00aeef;
                cursor: pointer;
              }
            }

            .quantityRequirequotesButton {
              button {
                background: #00aeef;
                border-radius: 37px;
                padding: 9px 12px;
                border: none;
                font-family: "Roboto";
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #ffffff;
                cursor: pointer;
              }
            }

            // .quotesLeftDetails {
            //     span {
            //         font-family: 'Roboto';
            //         font-weight: 400;
            //         font-size: 16px;
            //         line-height: 19px;
            //         color: #606060;

            //         .leftItems {
            //             color: #00AEEF;
            //         }
            //     }
            // }
          }
        }
      }
    }
  }
}

.three-grid-alignment {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.two-grid-alignment {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 23px;
}

.child-input-alignment {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.other-grid-alignment {
  display: grid;
  // grid-template-columns: 2fr 1fr;
  gap: 20px;
}
